/* Evidence Chains */

.ev-ch-mdl .modal-content:not(:has(.loading-animation)) {
    min-width: 400px;
    min-height: 300px;
}

.ev-ch-mdl :has(.loading-animation) {
    top: 20%;
}

.ev-ch-mdl .loading-animation {
    top: 20%;
    transform: none;
}

.ev-ch-wrapper {
    display: flex;
    column-gap: 32px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 15px;
    padding-bottom: 7px;
}

.ev-ch-container {
    text-align: center;
    position: relative;
    margin: 0 15px;
    padding-bottom: 7px;
    border-bottom: 1px solid #eaeaea;
}

.ev-ch-wrapper > div {
    display: inline-flex;
    flex-wrap: nowrap;
}

.ev-ch-container:last-child {
    /*border: 0;*/
}

.ev-ch-mdl-body {
    flex-grow: 1;
    padding: 0;
    min-height: 9rem;
}

.ev-ch-mdl-body:has(.loading-animation) {
    padding-top: 15%;
}

.ev-ch-mdl-body .btn-primary, .ev-ch-mdl-body .btn-default {
    color: black;
}

.ev-ch-mdl-footer {
    border-top: none;
    border-radius: 6px;
    border-top: 1px solid #eaeaea;
    background-color: #fff;
}

.ev-ch-mdl-close-btn {
    border: none;
    background: none;
    box-shadow: none;
    color: var(--active-color);
    font-size: 14px;
    margin-right: 13px;
    margin-top: -6px;
    text-transform: uppercase;
}

.ev-ch-mdl-close-btn:hover {
    border: none;
    background: none;
    box-shadow: none;
    color: #1E2530;
}

.ev-ch-fgm-wrp {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    /*margin: auto;*/
}

.ev-ch-fgm-wrp h4, .ev-ch-fgm-wrp h5 {
    text-align: center;
}

.ev-ch-resp-body-sm {
    width: 25px;
    line-height: normal;
}

.ev-ch-resp-body-md {
    width: 50px;
    line-height: normal;
}

.ev-ch-resp-body-lg {
    width: 100px;
    line-height: normal;
}

.ev-ch-fgm-edge-body {
    height: 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    margin: 1px auto;
}

.ev-ch-fgm-edge-body > * {
    height: 11px;
}

/* line */
.ev-ch-fgm-edge-seprt {
    margin-top: -7px;
    border-bottom: 4px solid #060606;
    flex: 1;
}

/* arrow head */
.ev-ch-fgm-edge-icon {
    margin-top: -4px;
    margin-left: -2px;
    font-size: 12px;
    flex: initial;
}

.ev-ch-fgm-node-srch-btn {
    display: flex;
    font-size: 16px;
    color: var(--active-color);
    width: 100%;
    text-decoration: none;
    vertical-align: middle;
    border: none;
    background: none;
    box-shadow: none;
    margin: 8px;
}

.ev-ch-fgm-node-srch-btn .search-icon {
    font-size: 19px;
    display: block;
    margin-left: 4px;
    margin-right: 16px;
}

.ev-ch-fgm-node-srch-btn:hover {
    border: none;
    background: none;
    box-shadow: none;
    color: var(--link-bold-color);
    text-decoration: none;
}

.ev-ch-fgm-node-title {
    font-size: 20px;
    word-break: break-word;
    color: #333;
    font-weight: bold;
    margin-bottom: 6px;
}

.ev-ch-fgm-node-type {
    text-transform: uppercase;
    font-size: 12px;
    color: #A1A1A1;
}

/* EdgeGroup */
#dataset-selector, #dataset-selector:active, #dataset-selector:focus, #dataset-selector:hover {
    background: none;
    border: none;
    box-shadow: none;
}

.ev-ch-edge-list-wrp {
    /*padding: 12px 0 0 3px;*/
}

.ev-ch-edge-list {
    padding: 0;
    /*margin: 0 0 0 14%;*/
    list-style: none;
    /*text-align: left;*/
    /*width: 120%;*/
    margin-bottom: 4px;
}

.ev-ch-edge-list li {
    /*padding-left: 15px;*/
    word-wrap: break-word;
    line-height: 1em;
    padding-bottom: 3px;
    text-align: center;
    /*margin-left: -60px;*/
}

.ev-ch-edge-list a {
    text-decoration: none;
}

.ev-ch-edge-list a:hover {
    cursor: pointer;
    text-decoration: underline;
}

.ev-ch-edge-list > a, .ev-ch-edge-list li a, .ev-ch-pop-all-dp li a {
    color: var(--link-color);
}

.ev-ch-edge-list > a {
    font-weight: bold;
    color: var(--link-bold-color);
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 4px 0;
    /*border-bottom: 1px solid blue;*/
}

.ev-ch-edge-tgl {
    color: #666666;
    display: inline-block;
}

.ev-ch-edge-tgl:hover {
    cursor: pointer;
}

.ev-ch-pop-all-dp {
    font-family: SourceSansPro-Regular sans-serif;
    max-width: 400px;
    min-width: 290px;
    /*width: 290px;*/
    max-height: 410px;
    padding: 5px;
    border-radius: 0;
    border: 1px solid #ebebeb;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
}

.ev-ch-pop-all-dp .popover-header {
    padding: 0;
    margin: 0;
    background-color: inherit;
    border: none;
}

.ev-ch-pop-all-dp ul {
    padding: 0;
    /*margin-top: -20px;*/
    list-style: none;
    clear: both;
}

.ev-ch-pop-all-dp ul > a {
    color: #3E51B5;
    font-weight: 700;
    font-size: 16px;
    /*margin-left: 16px;*/
    display: block;
    margin-bottom: 5px;
    max-width: 215px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ev-ch-pop-all-dp li {
    border-bottom: 1px solid #eaeaea;
    height: 28px;
    /*padding-left: 15px;*/
    padding-top: 5px;
    font-size: 15px;
}

.ev-ch-pop-all-dp li * {
    float: left;
}

.ev-ch-pop-all-dp li:hover {
    background-color: #F6F7FB;
}

.ev-ch-pop-all-dp li:hover .ctm__checkbox--input + span {
    background-color: #fefefe;
    border: 1px solid #eaeaea;
}

.ev-ch-pop-all-dp li:hover .ctm__checkbox--input + span:hover {
    border: 1px solid #4484F4;
}

.ev-ch-pop-all-dp li a {
    margin-left: 8px;
}

.ev-ch-pop-all-dp-body {
    /*width: 281px;*/
    width: 100%;
    overflow-y: auto;
    max-height: 280px;
    font-size: 13px;
    margin-top: 0;
    padding: 0;
    position: relative;
    /*top: -20px;*/
    /*margin-left: -16px;*/
}

.ev-ch-pop-all-dp-body a {
    text-decoration: none;
}

.ev-ch-pop-all-dp-body a:hover {
    text-decoration: underline;
}

.ev-ch-pop-all-dp-close-btn {
    /*left: 11px;
    top: -11px;*/
    float: right;
    /*color: #1a1a1a;*/
    position: relative;
    font-size: 20px;
    z-index: 1;
}

.ev-ch-edge-summary {
    margin: 0 0 5px 0;
    text-align: left;
}

.ev-ch-edge-summary b {
    width: 20%;
    display: inline-block;
    text-align: right;
    padding-right: 2px;
}

.ev-ch-edge-summary span {
    font-weight: bold;
    color: #aeaeae;
    width: 60%;
    display: inline-block;
    text-align: left;
    margin-left: 4px;
}

/* popup window */
.ev-ch-popup {
    /*font-family: SourceSansPro-Regular sans-serif;*/
    border-radius: 0;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.156862745098039);
    border: 1px solid #ebebeb;
    width: 280px;
    padding: var(--padding) 0;

    .dropdown-item, .dropdown-item:hover {
        padding: 4px 8px;
        font-family: inherit;
        font-size: 0.9rem;
        color: #212529;
        text-decoration: none;
    }
    .dropdown-item:active {
        background-color: #e0e0e0;
        border-color: #dbdbdb;
    }
}

.ev-ch-popup .dropdown-item span {
    margin-left: 4px;
}

.ev-ch-fgm-node-separator {
    height: 1px;
    width: 276px;
    border-top: 1px solid #e6e6e6;
    margin: 12px 0 12px -16px;
}

.ev-ch-fgm-wrp {
    width: 10rem;
}

.ev-ch-mdl .ev-ch-fgm-node-img .ball-lg div {
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
}

.ev-ch-mdl .ev-ch-fgm-node-img img {
    width: 100%;
    max-width: 100%;
}

.ev-ch-fgm-node-img:hover {
    cursor: pointer;
}

.ev-ch-fgm-node-label {
    word-break: break-word;
    color: #3E51B5;
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0 5px;
    text-decoration: none;
}

.ev-ch-fgm-node-label:hover {
    text-decoration: underline;
}

.ev-ch-fgm-node-type {
    color: #A1A1A1;
    font-weight: 700;
    font-size: 12px;
    margin: 0;
}

.ev-ch-mdl > .modal-content {
    border: none;
}

.ev-ch-mdl .modal-header {
    background-color: #e5e5e5;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    height: 50px;
    cursor: move;
}

.rnd-fixed-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.rnd-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
    background-position: bottom right;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
}

.ev-ch-zoomer {
    float: right;
    display: flex;
    flex-direction: row-reverse;
    z-index: 5;
    margin: 4px;
}

.ev-ch-zoom-btn {
    padding: 2px;
    background: none;
    float: right;
    top: 3px;
    right: 5px;
    width: 32px;
    height: 32px;
    border-radius: 0;
    border: none;
    box-shadow: none;
}

.ev-ch-zoom-btn img {
    width: 32px;
    height: 32px;
}

.ev-ch-export-btn {
    width: 32px;
    height: 32px;
    font-size: 24px;
    font-weight: 100;
}

.ev-ch-zoom-btn:hover {
    background-color: #cecece !important;
    border-radius: 20px;
}

.ev-ch-container :first-child {
    /*margin-top: 25px;*/
}

.ev-ch-zoom-btn:focus, .ev-ch-zoom-btn:active {
    outline: 0;
    border: none;
    box-shadow: none;
    background: none;
}

.ev-ch-resp-edge {
    /*padding: 0 23px 0 23px;*/
    /*z-index: -1;*/
    /*padding: 45px 23px 0 23px;*/
}

/* responsive settings */

.ev-ch-resp-body-lg .ev-ch-fgm-node-label {
    font-size: 16px;
}

.ev-ch-resp-body-lg .ev-ch-fgm-node-type {
    font-size: 12px;
}

.ev-ch-resp-body-lg .ev-ch-edge-summary {
    margin-left: 12%;
}

.ev-ch-resp-body-lg .ev-ch-edge-summary, .ev-ch-resp-body-lg .ev-ch-edge-tgl {
    font-size: 14px;
}

.ev-ch-resp-body-lg .ev-ch-edge-tgl {
    font-size: 14px;
    margin-left: -25px;
}

.ev-ch-resp-body-lg .ev-ch-edge-list-wrp {
    font-size: 14px
}

.ev-ch-resp-body-md .ev-ch-fgm-node-label {
    font-size: 14px;
}

.ev-ch-resp-body-md .ev-ch-fgm-node-type {
    font-size: 10px;
}

.ev-ch-resp-body-md .ev-ch-edge-summary {
    margin-left: 1%;
}

.ev-ch-resp-body-md .ev-ch-edge-summary, .ev-ch-resp-body-md .ev-ch-edge-tgl {
    font-size: 12px;
}

.ev-ch-resp-body-md .ev-ch-edge-list-wrp {
    font-size: 12px
}

.ev-ch-resp-body-sm .ev-ch-fgm-node-label {
    font-size: 12px;
}

.ev-ch-resp-body-sm .ev-ch-fgm-node-type {
    font-size: 8px;
}

.ev-ch-resp-body-sm .ev-ch-edge-summary .ev-ch-resp-body-sm .ev-ch-edge-tgl {
    font-size: 10px;
}

.ev-ch-resp-body-sm .ellipsis-icon {
    font-size: 22px;
    color: #3E51B5;
}

.ev-ch-resp-body-sm .ev-ch-edge-list-wrp {
    font-size: 10px
}

.ev-ch-title-pup {
    word-break: break-word;
    max-height: 200px;
    overflow: auto;
    display: block;
}

/* override react-bootstrap-carousel style */
.carousel-control {
    width: 0;
    color: #9e9e9e;
}

.carousel-control:focus, .carousel-control:hover {
    color: #aeaeae;
}

.carousel-inner > .item {
    -webkit-transition: -webkit-transform .3s ease-in-out;
    -o-transition: -o-transform .3s ease-in-out;
    transition: -webkit-transform .3s ease-in-out;
    -o-transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
}

.dataset-info.highlight {
    background-color: #c4e3f3;
    margin-left: 10px;
    margin-right: 10px;
    /*width: auto;*/
}

.no-evidence-chains {
    margin-top: 48px;
    top: 40%;
    position: relative;
    left: 35%;
    width: 300px;
}

