.navbar {
    display: flex;
    flex-direction: column;
    padding: 0 var(--bs-gutter-x, 1.25rem);
    background-image: none;
    background-color: #f8f8f8;
}

.navbar > .container-fluid {
    padding: var(--bs-gutter-y, 0.75rem) 0;
}

.navbar .nav >li >a {
    padding-top: 25px;
}
.navbar .navbar-toggle {
    padding: 10px;
    margin: 25px 15px 25px 0;
}

/* container margin*/
.navbar-footer {
    padding-left: 0;
}

/* nav panel */
.ctm-nav-text + span {
    display: none
}

.ctm-nav-text span {
    font-size: 19px;
    padding: 13px;
    border: 1px solid #DEDEDE;
    color: #737373;
    border-radius: 2px;
    background-color: #fff;
    margin-left: 2px;
    margin-top: 2px;
}

/* custom nav bar*/
.custom-nav-bar {
    list-style: none;
    margin: 2px 0 2px 1px;
    font-style: normal;
    font-size: 15px;
    -webkit-padding-start: 0;
}

.custom-nav-bar.tab-bar {
    margin-top: 2px;
    /*position: relative;*/
    /*bottom: -1px;*/
}

.custom-nav-bar li {
    display:inline-block;
    list-style:initial;
    margin-right: 15px;
    margin-bottom: 4px;
}

.custom-nav-bar li button:focus {
    outline: 0;
}

.navbar-form {
    padding-left: 0;
}

.navbar-menus {
    display: flex;
    flex-direction: row;
}

.navbar-menus .btn.dropdown-toggle {
    margin-left: 8px;
}

.navbar-menus, .search-box, .grid-filter {
    .btn.dropdown-toggle {
        font-size: 19px;
        height: 100%;
    }
}

.navbar-menus, .search-box, .grid-filter {
    .btn.dropdown-toggle:hover,
    .btn.dropdown-toggle:active,
    .btn.dropdown-toggle.active,
    .btn.dropdown-toggle:focus,
    .btn.dropdown-toggle.focus {
        color: #333;
        background-position: 0, -15px;
        border-color: #adadad;
    }
    .btn.dropdown-toggle:focus,
    .btn.dropdown-toggle.focus {
        border: var(--focus-border);
    }
    .show > .btn.dropdown-toggle.active,
    .show > .btn.dropdown-toggle:active,
    .show > .btn.dropdown-toggle.focus,
    .show > .btn.dropdown-toggle:focus {
        -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }

    .btn.active:focus,
    .btn.dropdown-toggle:hover,
    .btn.dropdown-toggle:focus,
    .show > .btn.dropdown-toggle,
    .show > .btn.dropdown-toggle:hover,
    .show > .btn.dropdown-toggle:focus {
        color: #000;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
}

.navbar-controls{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navbar-searchbox {
    display: flex;
    align-items: center;
    flex: auto;
}

.menu-item-navbar > button {
    /*margin-left: 8px !important;*/
    /*margin-top: 27px;*/
}

.menu-item-main-page{
    position: absolute;
    width: 100%;
    justify-content: right;
    /*top: 17px;*/
    right: 10px;
    display: flex;
    flex-direction: row;
    padding-top: 17px;
}


.navbar .navbar-right {
    right: 0;
    margin-top: 8px;
    margin-right: 20px;
}

.tab-span-disabled{
    /* Use opacity here in case of altered background color */
    color: var(--default-color);
    opacity: var(--disabled-opacity);
    display: block;
}

.app-header {
    /* needs to stay above query summary */
    z-index: 2;
}

.navbar-logo {
    height: 74px;
}

.app-logo {
    height: 113px;
    -webkit-transition: height 1s ease-in-out;
    -moz-transition: height 1s ease-in-out;
    transition: height 1s ease-in-out;
}

.app-logo.small {
    -webkit-transition: margin-top, height 1s ease-in-out;
    -moz-transition: margin-top, height 1s ease-in-out;
    transition: margin-top, height 1s ease-in-out;
    height: 48px;
    margin-top: -25px;
}

.logo-wrapper{
    margin: 20px;
    text-align: center;
}

.navbar-footer.navbar-nav {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

/* These definitions override our outdated minified bootstrap theme */
.landing-page, .navbar {
    .btn-primary.disabled,
    .btn-primary.disabled.active,
    .btn-primary.disabled.focus,
    .btn-primary.disabled:active,
    .btn-primary.disabled:focus,
    .btn-primary.disabled:hover,
    .btn-primary[disabled],
    .btn-primary[disabled].active,
    .btn-primary[disabled].focus,
    .btn-primary[disabled]:active,
    .btn-primary[disabled]:focus,
    .btn-primary[disabled]:hover {
        background-color: #e0e0e0;
    }
}

