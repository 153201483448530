.filter-summary {
    display: block;
    font-style: italic;
    font-size: 14px;
    height: 50%;
    margin: auto 0;
}

.grid-filter {
    padding-top: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 10px;
}

.grid-filter.undecorated {
    border: none;
}

.grid-filter-input {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 1;
    align-items: center;
}

.grid-filter-input > input {
    /*margin: -2px 0 -4px 5px;*/
    width: 65%;
    border: 1px dotted #9a9a9a;
    padding: 2px;
    flex: 1;
    margin-left: 8px;
    margin-top: 2px;
    box-sizing: content-box;
    height: 26px;
}

.grid-filter-input .dropdown {
    margin-top: 2px;
}

.grid-filter-input > .button {
    padding: 4px;
    margin: auto 8px;
    border: 1px solid transparent;
    width: 140px;
    height: 50%;
    text-align: center;
}
.grid-filter-input > .button:hover, .grid-filter > .button:focus {
    border: 1px dotted #a0a0a0;
}

.grid-filter .search-link {
    text-decoration: none;
    margin-left: 4px;
    margin-top: 2px;
    padding: 0 4px;
    height: 28px;
    width: 28px;
    font-size: 19px;
    display: inline-block;
}

.grid-filter select {
    /*margin-left: 12px;*/
}

.grid-filter .dropdown {
}

.grid-filter button.search-terms-display-toggle,
.grid-filter button.salts-display-toggle {
    font-size: 1em;
}

.grid-filter button, .grid-filter .btn.dropdown-toggle {
    font-size: 19px;
    width: 32px;
    height: 32px;
    margin-left: 4px;
    padding: 0;
    background: #fff;
    /*color: #888;*/
    color: #737373;
    border: 1px solid #CCC;
}

.grid-filter .btn.dropdown-toggle {
    padding-top: 2px;
}

.grid-filter button:hover,
.grid-filter button:active {
    color: #737373;
    background-color: #e0e0e0;
    background-position: 0, -15px;
    border-color: #adadad;
}

.grid-filter .show>button.hover,
.grid-filter .show>button.active {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.grid-filter .btn:hover,
.grid-filter .show>.btn,
.grid-filter .show>.btn:hover,
.grid-filter button:hover,
.grid-filter .show>button,
.grid-filter .show>button:hover,
.grid-filter button.active:not(.focus) {
    color: #333;
    /*background-color: #e6e6e6;*/
    border-color: #8c8c8c;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.grid-filter .btn:focus,
.grid-filter .btn.focus,
.grid-filter button:focus,
.grid-filter button.focus {
    color: #000;
    background-color: #fff;
    /*background-color: #e6e6e6;*/
    /*border-color: #adadad;*/
    border-color: #8c8c8c;
}

.grid-filter .dropdown-menu.show {
    /*background: inherit;*/
}

.grid-filter .apply-filter,
.grid-filter .clear-filter,
.grid-filter .select-all {
    font-weight: bold;
    color: #3126B4;
}

.grid-filter .dataset-options.active .dropdown-toggle:focus,
.grid-filter .dataset-options.active .dropdown-toggle.focus {
    /*background-color: #fff;*/
    box-shadow: none; /*0 0 0 0.1rem rgba(49, 132, 234, 0.5);*/
    border-color: var(--focus-border-color);
}

.grid-filter .dataset-options.active .dropdown-toggle {
    color: #3126B4;
    border-color: #8c8c8c;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    background-color: #e6e6e6;
}

.grid-filter .dataset-options .dropdown-menu.show {
    columns: 2;
}

.grid-filter .dropdown-item .btn.dropdown-toggle {
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
    background: inherit;
}

.grid-filter .dropdown-item.show .btn.dropdown-toggle {
    background: red;
}
