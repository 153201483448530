/* search result*/

.sr-row {
    width: 50%; /* hack to prevent width expansion beyond parent */
    flex: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    line-height: normal;
}

.search-result-image {
    width: 100px;
    height: 100px;
    display: block;
}

.image-hover {
    max-width: 300px;
}

.search-result-image-popover {
    width: 275px;
    height: 275px;
    padding: 8px;
}

.grid-viewport-row:last-child .grid-view-row {
    padding-bottom: 20px;
}

.grid-view-row {
    display: flex;
    flex-direction: row;
    /*align-items: stretch;*/
    /*width: 100%;*/
}

.grid-view-row .collapsible-scroller {
    width: 480px;
    overflow-x: hidden;
}

.search-result {
    display: flex;
    flex-direction: column;
    .sr-desc:has(.markdown) {
        margin-top: 8px;
    }
}

.sr-row-img{
    margin-right: 20px;
}

.popover .compound-structure-image {
    margin: 4px;
}

.sr-row-img .compound-structure-image {
    width: 128px;
    height: 128px;
}

.sr-desc, div.sr-desc {
    display: flex;
    flex-direction: row;
    gap: 0.2rem;
    word-wrap: break-word;
    font-size: 14px;
    color: #545454;
    margin-top: 2px;
    margin-bottom: 2px;
    p {
        margin-bottom: 0;
    }
    .collapsible-collapsible {
        width: 100%;
    }
    .collapse {
        overflow-y: auto;
        max-height: 150px;
    }
}

.sr-header{
    font-weight: 400;
    font-size: 20px;
}

.sr-links{
    clear: both;
    margin-top: 3px;
}

.sr-link .search-icon {
    margin-right: 3px;
    display: block;
    float: left;
    margin-top: 3px;
}

.sr-header a, .sr-header a:hover{
    text-decoration: none;
}

.sr-title{
    color: #3126B4;
    word-break: break-word;
    cursor: pointer;
}

.sr-link{
    color: #3126B4;
    /*font-size: 14px;*/
    font-weight: 400;
    margin-right: 15px;
    border: none;
    background: none;
    padding: 0;
}

.sr-link.disabled, .sr-link:disabled, .sr-link-disabled, button.sr-link-disabled {
    /*color: #777;*/
    opacity: var(--disabled-opacity);
    cursor: default;
}

.sr-link:focus{
    outline: 0;
}

.sr-total{
    font-size: 15px;
    color: #808080;
}

.source-link{
    color: #1A7671;
    font-size: 16px;
    margin-right: 10px;
}

.source-link.dropdown {
    margin-top: -2px;
}

.source-link-b-title, .block-title{
    color: #777;
    font-weight: bold;
    white-space: nowrap;
}

.source-links, .source-links .show {
    .btn.dropdown-toggle,
    .btn.dropdown-toggle:active,
    .btn.dropdown-toggle.active {
        color: #1A7671 !important;
        background: none !important;
        border: none !important;
        box-shadow: none !important;
        padding: 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-shadow: none;
    }
}

.grouped-source-links:hover, .grouped-source-links:active, .grouped-source-links:focus{
    background: none;
    border: none;
    box-shadow: none;
    -webkit-box-shadow: none;
    color: #1A7671;
}

.grouped-source-links .dropdown-menu {
    border-radius: 0;
    padding: 0;
    max-height: 300px;
    overflow-y: auto;
}

.search-link {
    color: #3126B4;
    font-size: 14px;
    text-align: start;
}

.search-link:hover{
    text-decoration: none;
    color: #3126B4;
}

.search-link span{
    top: 3px;
}

.search-link span:last-child {
    margin-left: 0.25rem;
}

.image-hover{
    left: 32px !important;
}

.source-links {
  display: inline-flex;
  flex-wrap: wrap;
}

.source-links a {
    text-decoration: none;
}

.search-item-footer a {
    text-decoration: none;
}

