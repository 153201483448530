.login-form, .help-form {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #EFEFEE;
}

.login-body, .help-body {
    position: absolute;
    top: 15%;
    left: 35%;
    width: 441px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.247058823529412);
}

.login-img-wrp, .help-img-wrp {
    position: relative;
    z-index: 1;
    text-align: center;
    margin-top: 55px;
}

.login-img-wrp > img, .help-img-wrp > img {
    width: 207px;
    height: 95px;
}

.login-fields {
    padding: 40px;
    .ctm__checkbox {
        display: inline-block;
    }
}

.login-control-username{
    border: 1px solid #DEDEDE;
    width: 100%;
    margin-bottom: 12px;
    padding: 16px 10px;
    color: #797979;
    font-size: 16px;
}

.login-control-pass{
    border: 1px solid #DEDEDE;
    width: 100%;
    margin-bottom: 12px;
    padding: 16px 10px;
    color: #797979;
    font-size: 16px;
}

.login-submit{
    color: #fff;
    background-color: #4285F4;
    border: 1px solid #4285F4;
    width: 100%;
    height: 50px;
    font-size: 22px;
    border-radius: 2px;
    margin-top: 5px;
    text-transform: uppercase
}

.login-submit:disabled {
    background: #cecece;
    border: 1px solid #aeaeae;
}

.signup-block{
    text-align: center;
    padding-top: 15px;
}

.signup-btn{
    font-size: 15px;
    color: var(--link-color);
    background: none;
    border: none;
    margin: 0;
}

.signup-btn:focus {
    outline: 0;
}

.signup-btn:hover{
    text-decoration: underline;
    color: var(--link-hover-color);
}

#forgotPass.signup-btn, #resendConfirmation.signup-btn {
    float: right;
}

#forgotPass:hover, #resendConfirmation:hover {
    text-decoration: underline;
    cursor: pointer;
}

.cb-rem-me{
    float: left;
    width: 16px;
    height: 16px;
    margin: 4px;
}

.cb-rem-me + span{
    font-size: 16px;
    margin-left: 5px;
}

.send-vcode-btn{
    float: right;
    margin-top: -3px;
}

.send-vcode-btn:disabled{
    /*color: #aeaeae;*/
}

.send-vcode-btn:disabled:hover{
    text-decoration: none;
}
