.ctm__checkbox * {
    cursor: pointer;
}

.ctm__checkbox--input {
    display: none;
}

.ctm__checkbox--span {
    margin-top: 0.2rem;
}

.ctm__checkbox--input + span {
    display: flex;
    width: 18px;
    height: 18px;
    background-color: var(--bg-color);
    align-items: center;
    justify-content: center;
    color: var(--bg-color);
    border: 1px solid var(--button-border-color);
    box-sizing: border-box;
    transition: all 150ms;
    border-radius: 2px;
    margin: auto;
}

.ctm__checkbox--input + span:before {
    content: "\E013";
    margin-top: 0.1rem;
    margin-right: 0.2rem;
    font-family: 'Glyphicons Halflings', sans-serif;
    font-size: 10px;
    width: 100%;
    height: 100%;
    text-align: center;
    color: transparent;
    background-color: transparent;
    /*border: 1px solid var(--default-color);*/
}

.ctm__checkbox--input:disabled + span {
    background-color: transparent;
}

.ctm__checkbox.disabled, .ctm__checkbox:disabled {
    * {
        color: var(--disabled-color);
        cursor: default;
    }
}

.ctm__checkbox--input:disabled:checked + span:before {
    color: var(--disabled-color);
    cursor: default;
}

.ctm__checkbox--input:checked:not(:disabled) + span:before {
    color: var(--body-color)
}

.radio .ctm__checkbox--input + span {
    border-radius: 50%;
}

.radio .ctm__checkbox--input + span:before {
    content: "";
    width: 75%;
    height: 75%;
    border-radius: 50%;
    margin: auto;
}

.radio .ctm__checkbox--input:checked + span:before {
    background-color: var(--default-color);
}

.disabled .radio .ctm__checkbox--input:checked + span:before,
.radio.disabled .ctm__checkbox--input:checked + span:before {
    background-color: var(--disabled-bg-color);
}

.dropdown-item:hover:not(.disabled) .ctm__checkbox--input + span,
.ctm__radio:hover:not(.disabled) .ctm__checkbox--input + span,
.ctm__radio--input:not(:disabled) + span:hover,
.ctm__checkbox:hover:not(.disabled) .ctm__checkbox--input + span,
.ctm__checkbox--input:not(:disabled) + span:hover {
    border: 1px solid var(--active-color);
}

.ctm__checkbox label {
    display: inline-flex !important;
}

.ctm__checkbox label:last-child {
    text-indent: 0.25rem;
}

.dropdown .ctm__checkbox {
    width: 100%;
    label {
        width: 100%;
        span:last-child {
            width: 100%;
        }
    }
}
